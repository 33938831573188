//
// Form Control
//

// Form control
.form-control {
  box-shadow: none !important;
  font-weight: 400;
  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Solid style
  &.form-control-solid {
    background-color: $input-solid-bg !important;
    border-color: $input-solid-bg !important;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color !important;
    transition: $transition-input !important;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus !important;
      border-color: $primary !important;
      color: $input-solid-color !important;
      transition: $transition-input !important;
    }
  }

  // Transparent style
  &.form-control-transparent {
    background-color: transparent !important;
    border-color: transparent !important;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}

// Form control solid bg
.form-control-solid-bg {
  background-color: $input-solid-bg;
}

.highlight-field {
  box-shadow: 0px 0px 10px -1px rgb(13 213 16) !important;
}

.form-label {
  font-weight: 500;
  font-size: 0.925rem;
  line-height: 1.25;
  color: #011229;
  margin-bottom: 4px;
}

.react-tel-input {
  font-family: inherit;
  font-size: 0.925rem;
}

.react-tel-input .form-control {
  font-size: 0.925rem;
  padding-left: 40px;
  border-radius: 0.325rem;
}
