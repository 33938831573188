.oa-loader {
    &.oa-loader--inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .spinner-border.spinner-lg {
            height: 48px;
            width: 48px;
        }
    }
}