.oa-phone-field {
    .form-control {
        width: 100% !important;

        &.form-control-lg {
            font-size: 1.15rem;
            border-radius: 0.625rem;
            font-size: 1.15rem;
            font-weight: 500;
            line-height: 1.5;
        }
    }
    .flag-dropdown  {
        border: unset !important;
        background-color: unset !important;

        &.open {
            z-index: 2;
            background: unset !important;
            border-radius: 3px 0 0 0;

            .selected-flag {
                background: unset !important;
            }
        }

        .selected-flag {
            &:hover, &:focus {
                background-color: unset !important;
            }
        }
    }
}
