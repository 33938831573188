.order-create {
  .add-visa-type,
  .add-insurance-type {
    position: relative;

    .select-visa-form,
    .select-insurance-form {
      .form__submit--wrapper {
        position: sticky;
        background: $white;
        padding: 0;
        bottom: calc(0px - 1.25rem - 2.25rem - 43px);
        transition: all 250ms ease-in-out;

        &.sticky {
          bottom: 0;
          padding: 1.25rem 0 2.25rem;
        }
      }
    }
  }

  .insurance-product__field {
    display: grid;
    grid-template-columns: 1fr 3fr 38px;
    grid-gap: 8px;
    cursor: pointer;
  }
  .stepper-steps-card {
    position: sticky;
    top: calc(65px + 1.5rem);
  }
  @media (max-width: 1024px) {
    .stepper-steps-card {
      position: inherit;
    }
  }
}

.master-products-wrapper {
  .master-product.accordion:not(:last-child) {
    margin-bottom: 1rem !important;
  }
}

.document-card {
  &.passport-image-container {
    height: 230px;
  }

  > .card-body.document-empty,
  > .card-body.document-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 5/3;
    overflow: hidden;
    padding: unset;

    .document-card--upload-label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .document-card--upload-input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
  > .card-body.document-empty {
    position: relative;
    cursor: pointer;

    .loading-overlay {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: #0000009c;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 250ms ease-in-out;

      &.active {
        opacity: 100;
      }
    }
  }
  > .card-body.document-thumbnail {
    position: relative;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    > .document-thumbnail--overlay {
      // top: 100% !important;
      top: calc(100% - (30px + 2rem)) !important;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      background-color: rgba(0, 0, 0, 0.75);
      // transition: top 250ms ease-in-out;

      .document-thumbnail--input-file {
        display: none;
      }
      label.disabled > .btn {
        pointer-events: none;
        opacity: 0.65;
      }
    }
  }
}

.handsontable .htCommentCell:after {
  border-top: 6px solid yellow !important;
}

.document-card--upload-label {
  cursor: pointer;
}
.document-card--upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
