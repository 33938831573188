.FIE_topbar-save-button {
  background-color: #347cef !important;
  height: 34px !important;

  span {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}

.oa-truncated-text {
  > div {
    span {
      color: $primary;
      cursor: pointer;

      &:hover {
        color: $primary-active;
      }
    }
  }
}

.toggle-option {
  cursor: pointer;
  padding: 5px 10px;
  border: none;
  background: none;
  font-size: 14px;
  color: #616876;
}

.toggle-option.active {
  text-decoration: underline;
  text-underline-offset: 11px;
  color: #3a86ff;
  font-weight: 500;
}

#dropdown-custom::after {
  display: none;
}

.doc-review-editor .FIE_topbar-buttons-wrapper {
  display: none !important;
}

.ql-editor:focus {
  border: 1px solid #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}
