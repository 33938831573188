.alert {
  &.alert-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .alert-component--body {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .alert-component--action {
      white-space: nowrap;
    }
  }
}
