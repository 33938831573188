.order-view {
  // .order-view--basic-details {}

  // .order-view--tabs {}

  .order-view--traveller-card {
    .document-card {
      > .card-body.document-empty,
      > .card-body.document-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 5/3;
        border: 1px solid #b5b5c2;
        border-radius: $oa-border-radius;
        overflow: hidden;
        padding: unset;

        .document-card--upload-label {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .document-card--upload-input {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
      }
      > .card-body.document-empty {
        border: 1.54px dashed $oa-primary;

        &.disabled {
          border: 1.54px dashed $oa-secondary;

          > label.document-card--upload-label {
            cursor: unset !important;
          }
        }
      }
      > .card-body.document-thumbnail {
        position: relative;

        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        > .document-thumbnail--overlay {
          // top: 100% !important;
          top: 0 !important;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
          background-color: rgba(0, 0, 0, 0.4);
          // transition: top 250ms ease-in-out;

          .document-thumbnail--input-file {
            display: none;
          }
          label.disabled > .btn {
            pointer-events: none;
            opacity: 0.65;
          }
        }
      }
    }
  }

  .table-responsive {
    tr.data-table__row--sub-component {
      .action-conversation {
        .comment-box {
          position: relative;

          button {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }

  .order-action__action-buttons--wrapper {
    .comments-icon-btn {
      position: relative;

      .comments-count {
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
  }

  .card.order-events {
    .card-body {
      .event-card {
        display: flex;
        margin-bottom: 2rem;

        &.archived {
          .event-card--details {
            border-left: 3px solid $secondary;
          }
        }

        .event-card--timing {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 128px;
        }
        .event-card--details {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
          padding-right: 1rem;
          border-left: 3px solid $primary;
          min-height: 80px;

          > span {
            overflow-wrap: anywhere;
          }
        }
        .event-card--members {
          width: 200px;
          display: flex;
          align-items: center;
        }
        .event-card--actions {
          width: 48px;
          display: flex;
          align-items: center;
          justify-content: end;
        }
      }
    }
  }
}

.document-preview {
  overflow: hidden;

  .modal-header {
    cursor: move;
  }
}

.comment-list {
  max-height: 300px !important;
  overflow-y: scroll !important;
}
