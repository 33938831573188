.oa-file-upload-field {
  .oa-file-upload-field__input-wrapper {
    position: relative;

    input {
      font-size: inherit;
      font-weight: 400;
      padding-right: 3rem;
    }

    input::file-selector-button {
      display: none;
    }

    span.svg-icon {
      position: absolute;
      top: 12px;
      bottom: 12px;
      right: 12px;
      aspect-ratio: 1/1;
    }
  }
}
