.oa-table-component.MuiDataGrid-root {
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  &.no-hover {
    .MuiDataGrid-main {
      .MuiDataGrid-row:hover {
        background-color: unset !important;
      }
    }
  }
}
.oa-table-component.MuiDataGrid-root {
  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-cell:focus {
    outline: none !important;
  }
  .MuiDataGrid-withBorderColor {
    border-color: #f0f2f5 !important;
  }
  .MuiDataGrid-footerContainer {
    .MuiTablePagination-root {
      .MuiToolbar-root {
        .MuiTablePagination-displayedRows {
          margin: unset !important;
        }
      }
    }
  }

  &.pointer {
    .MuiDataGrid-cell {
      cursor: pointer !important;
    }
  }
}

// bulk upload datagrid
.data-grid-error {
  border: 1px solid #d63939 !important;
  background-color: #fbebeb;
  position: relative;
}

.data-grid-triangle {
  width: 100%;
  height: 100%;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  cursor: help;
}

.data-grid-id-cell {
  min-width: '20px !important';
  max-width: '20px !important';
  font-size: '14px';
  color: #a2a5b5;
}

.data-grid-cell-first-col {
  background-color: #eeeeee !important; /* Change the color as needed */
}

// classic-table
.classicTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f0f2f5;
  border-radius: '8px';
}

.classicTable th,
.classicTable td {
  border: 1px solid #f0f2f5;
  padding: 8px 12px;
  text-align: left;
}
