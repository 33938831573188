div.oa_range-picker,
section.oa-datepicker-field,
section.oa-time-picker {
  display: flex;
  flex-direction: column;

  .MuiFormControl-root {
    &.MuiTextField-root {
      .MuiInputBase-root {
        &.Mui-focused {
          &.MuiInputBase-formControl {
            background-color: $input-solid-bg-focus;

            fieldset.MuiOutlinedInput-notchedOutline {
              border-color: $primary !important;
              border: 1px solid $primary !important;
            }
          }
        }

        &.MuiInputBase-formControl {
          background-color: $input-solid-bg;

          fieldset.MuiOutlinedInput-notchedOutline {
            border: unset !important;
          }
        }
      }
    }
  }
}

.oa_range-picker {
  .MuiFormControl-root {
    min-width: 210px;
  }
}
