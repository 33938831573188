.search-orders {
    .oa-text-search {
        height: 3rem;
    }
    .oa-select-search {
        font-size: 1.1rem;
        color: #5e6278 !important;

        .oa-select__control {
            min-height: 3rem;
            border-radius: 0.475rem;

            .oa-select__placeholder {
                color: #a1a5b8 !important;
            }
        }
    }
}

@keyframes animation-order-table-drawer-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.order-table--drawer-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: rgba(0,0,0,.2);
    animation: animation-order-table-drawer-fade-in .3s ease-in-out 1;
    z-index: 109;
    
    &.on {
        background: #00000069;
    }
}
.order-table--drawer {
    width: 500px !important;
    display: flex !important;
    overflow: auto;
    z-index: 110;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -500px;
    background-color: #fff !important;
    transition: all .3s ease-in-out;
    
    &.open {
        right: 0;
        transform: none;
        box-shadow: 0 1px 9px -3px rgb(0 0 0 / 5%);
    }
}
