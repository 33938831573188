//
// Modal
//

// Base
.modal {
  // Mobile header
  .modal-header {
    align-items: center;
    justify-content: space-between;
    @include border-top-radius($modal-content-border-radius);

    // Headings
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
    }
  }

  // Mobile dialog
  .modal-dialog {
    outline: none !important;
  }
}

// Utilities
.modal-rounded {
  @if $enable-rounded {
    border-radius: $modal-content-border-radius !important;
  }
}

.markup-form-modal {
  .oa-markup-text-field {
    position: relative;

    button.btn-close.close {
      position: absolute !important;
      top: 3.15rem !important;
      right: 8rem !important;
    }
  }
}

// css for addMarkupModal component
button.btn-close.close {
  position: absolute !important;
  top: 11rem !important;
  right: 9rem !important;
}

.document-preview {
  .modal-content {
    width: fit-content !important;
    margin: 0 auto;
  }
}

.image-preview {
  height: 60vh; /* Adjust the maximum height as needed */
}

.modal-1240w {
  max-width: 1240px;
}
