.dashboard-data-range-filter {
  border-radius: 0.625rem;

  .MuiFormControl-root {
    &.MuiTextField-root {
      .MuiInputBase-root {
        &.MuiInputBase-formControl {
          background-color: $white !important;

          fieldset.MuiOutlinedInput-notchedOutline {
            border: unset !important;
          }
        }
      }
    }
  }
}

.dashboard-tiles {
  &.card.visa-to-be-expired {
    overflow: hidden;

    > .visa-to-be-expired__header-body {
      padding: 2rem 2.25rem;
      display: flex;
      justify-content: space-between;
      background: #fbebeb;
      min-height: 200px;
    }
    > .card-body.dashboard-tiles__body {
      > .dashboard-tiles__body--floting {
        margin-top: -130px;
        overflow: auto;
        background: $white;
        border-radius: $card-border-radius;
        min-height: 180px;
        max-height: 310px;
      }
    }
  }

  &.card.upcoming-events {
    > .upcoming-events__header-body {
      padding: 2rem 2.25rem;

      .upcoming-events__week {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .upcoming-events__week-day {
          position: relative;
          height: 56px;
          width: 38px;
          border-radius: 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background: $oa-secondary-light;
          }

          &.selected {
            background: $primary;
            color: $white;
          }

          &.disabled {
            background: none;
            color: $oa-text-secondary;
            cursor: unset;
            pointer-events: none;
          }

          &.filled {
            border: 2px solid $danger;
          }

          span {
            position: absolute;
            padding: 2px 4px;
            background: $danger;
            bottom: -12px;
          }
        }
      }
    }
    > .card-body.upcoming-events__body {
      overflow: auto;
    }
  }
  .dashboard-tiles__btn--arrow {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $btn-border-radius;
    background: $oa-secondary-light;
    transition: all 250ms ease-in-out 0ms;
    cursor: pointer;

    &:hover {
      background: $oa-secondary;
      color: $white;
    }

    &.disabled {
      opacity: 50%;
      cursor: unset;
      pointer-events: none;
    }
  }
}


.react-grid-item {
  display: flex;
  flex-direction: column;
}

#content {
  width: 100%;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #b1b3b6;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

