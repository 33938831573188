.novu-popover {
  position: absolute;
  transform: translate3d(-380px, 285px, 40px);
}

.infinite-scroll-component {
  font-family: 'Inter', sans-serif;
  text-transform: capitalize;
  font-size: 13px;
}

.sc-bjfHbI {
  line-height: 20px !important;
}

.sc-hHTYSt {
  opacity: 1.5 !important;
}

.pyoUO::before {
  background: linear-gradient(0deg, #049df5 0%, #049df5 100%) !important;
}

.sc-eDvSVe {
  display: none !important;
}

.notification-icon-button {
  cursor: pointer;
  margin: 2px -19px 0px 0px;
  border-radius: 0.475rem;
}

.notification-icon-button:hover {
  padding: 17px 17px 17px 17px;
  background: url('../../../components/Icons/bell-hover.svg');
  background-size: auto;
  height: 0;
}

.badge.notification-count {
  margin: 10px 0px 34px 4px;
}

button.mantine-Button-filled.mantine-Button-root {
  padding: 0px 0px 15px 16px;
  background: url('../../../components/Icons/Close.svg') !important;
  background-size: auto;
  width: 0px;
  height: 0px;
  margin-left: 28rem;
  bottom: 5.7rem;
}

button.mantine-Button-filled.mantine-Button-root:disabled {
  opacity: 0.5;
}

button.mantine-Button-filled.mantine-Button-root:after:focus {
  outline: 0;
}

button.mantine-Button-filled.mantine-Button-root:after:hover {
  color: #049df5;
  border-color: #049df5;
}

button.mantine-Button-filled.mantine-Button-root:after:active {
  color: #049df5;
  border-color: #049df5;
}

.mantine-odgo4w {
  position: absolute !important;
}

.mantine-7ievpy {
  display: none;
}

//loading spinner
.mantine-1avyp1d {
  stroke: #049df5 !important;
}

//side-strips
.hQCdoo:before {
  background: linear-gradient(0deg, #049df5 0%, #049df5 100%) !important;
}

//count
.mantine-1317kw4 {
  background: linear-gradient(0deg, #049df5 0%, #049df5 100%) !important;
}

// //novu branding footer
.sc-jSUZER {
  display: none !important;
}

.nc-footer {
  display: none !important;
}

.mantine-Badge-root {
  background: linear-gradient(0deg, #049df5 0%, #049df5 100%) !important;
}

.nc-notifications-list-item-buttons {
  display: none !important;
}

.nc-notifications-list-item::before {
  background: linear-gradient(0deg, #049df5 0%, #049df5 100%) !important;
}

.nc-bell-button-dot {
 display: none !important;
}
