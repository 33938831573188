//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #3a86ff;
$primary-active: if(isDarkMode(), #187de4, #347cef);
$primary-light: if(isDarkMode(), #212e48, #f1faff);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #0bb783, #2fb344);
$success-active: if(isDarkMode(), #04aa77, #229334);
$success-light: if(isDarkMode(), #1c3238, #eaf7ec);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #8950fc, #6b3ce1);
$info-active: if(isDarkMode(), #7337ee, #572fdc);
$info-light: if(isDarkMode(), #2f264f, #f0ebfc);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #f64e60, #d63939);
$danger-active: if(isDarkMode(), #ee2d41, #c13434);
$danger-light: if(isDarkMode(), #3a2434, #fbebeb);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #ffa800, #f59f00);
$warning-active: if(isDarkMode(), #ee9d01, #e19200);
$warning-light: if(isDarkMode(), #392f28, #fef5e5);
$warning-inverse: #ffffff;
