.oa-select-container {
  .oa-select__control {
    min-height: calc(1.5em + 1.1rem + 2px);
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    box-shadow: none !important;
    transition: $transition-input;

    &.oa-select__control--is-focused {
      background-color: $input-solid-bg-focus;
      border-color: $primary;
      border: 1px solid $primary;
    }

    // &.oa-select__control--menu-is-open {}
  }
  // .react-select__value-container {}
  // .react-select__indicators {}
  // .react-select__menu {}
  // .react-select__menu-list {}
  // .react-select__option {}
}

.DateInput_input__small {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
  height: calc(1.5em + 1.1rem + 2px) !important;
}

.DateInput_input__small::placeholder {
  color: $gray-400;
  opacity: 1;
}

.DateInput_input__small:-ms-input-placeholder {
  color: $gray-400;
}

.DateInput_input__small::-ms-input-placeholder {
  color: $gray-400;
}
