// MasterProduct
.master-product.accordion {
  > .accordion-item {
    overflow: hidden !important;

    > .accordion-header {
      > .accordion-button {
        align-items: flex-start !important;
        background-color: $white !important;

        > .master-product__card-header--content {
          flex-grow: 1;
          width: inherit;
        }
      }
    }
    > .accordion-collapse {
      > .accordion-body {
        > .master-products--list {
          .master-products--item {
            border: unset !important;

            &:not(:last-child) {
              border-bottom: 1px solid $border-color !important;
            }
          }
        }
      }
    }
  }
}
