//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

@import '../../../assets/sass/styles.scss';

.order-list-tab .btn {
  color: #b5b5c2;
  padding: 15px 10px !important;
  border-radius: 0 !important;
}
.order-list-tab .btn:hover {
  color: #009ef7;
  border-bottom: 2px solid #009ef7;
}
.order-list-tab .btn.active {
  color: #009ef7;
  border-bottom: 2px solid #009ef7 !important;
}
.order-search-clear {
  right: 10px;
  top: 17px;
}
.order-search a {
  cursor: pointer !important;
}

.highlight-test-mode-container {
  z-index: 1112;
  position: absolute;
  width: 100%;
  animation: fadein 2s;
}

.highlight-test-mode-container .horizontal-line {
  background-color: #ffc400;
  width: 100%;
  height: 5px;
  position: fixed;
  top: 0;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
  50% {
    background-color: #fdee4b;
    -webkit-box-shadow: 0 0 20px #fdee4b;
  }
  100% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
  50% {
    background-color: #fdee4b;
    -webkit-box-shadow: 0 0 20px #fdee4b;
  }
  100% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
  50% {
    background-color: #fdee4b;
    -webkit-box-shadow: 0 0 20px #fdee4b;
  }
  100% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
}

@keyframes glowing {
  0% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
  50% {
    background-color: #fdee4b;
    -webkit-box-shadow: 0 0 20px #fdee4b;
  }
  100% {
    background-color: #ffc400;
    -webkit-box-shadow: 0 0 3px #ffc400;
  }
}

.highlight-test-mode-container .content {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.highlight-test-mode-container .content .trapezoid {
  height: 40px;
  width: auto;
  position: fixed;
  top: -18px;
  background-color: #ffc400;
  clip-path: polygon(10% 97%, 90% 97%, 100% 41%, 0 40%);
  border-radius: 16px;
  text-align: center;
  padding-top: 21px;
  padding-left: 0px;
  color: #1d273b;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  padding-left: 20px;
  padding-right: 20px;
}

.bg-white {
  background-color: #ffffff;
}

// OARangePicker
.oa-range-picker {
  background: #f6f8fa;
  border-radius: 8px;
  border: none;
  padding: 8px 11px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #7f8297;
  min-width: 190px;
}
.MuiPickersLayout-shortcuts.oa_range-picker--shortcuts {
  padding: 1.5rem 0;
  border-right: 2px solid #e0e0e0;

  .list-group {
    .list-group-item {
      border: unset;
      border-radius: unset;
    }
  }
}

#kt_content_container {
  max-width: 100%;
}
#kt_aside {
  display: none;
}
.upload-doc-disabled {
  opacity: 0.4;
  .btn {
    cursor: not-allowed;
  }
}

.oa-order-review-table {
  thead {
    border-bottom: 1px solid #eff2f5;
  }
  tbody {
    .service-charge-find {
      border-top: 1px solid #eff2f5;
    }
  }
}

.product-list-card-hover {
  cursor: pointer;
  &:hover {
    border-style: dashed;
    background-color: #f1faff;
  }
}

.quotation-doc-table {
  tr {
    td {
      vertical-align: top;
    }
  }
}

.quotation-info-card {
  background: #00183a;
  border-radius: 16px;
  padding: 30px 30px;
  .quotation-info-card-img {
    max-width: 100%;
  }
}

.oa-table-cell-ellipsis {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.passport-image {
  border: solid 1px var(--black-90);
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.passport-image img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

.passport-image-icon {
  position: absolute;
  top: -10px;
  border-radius: 8px;
  border: 1px solid;
  left: 98%;
}

.tooltip-text-passport {
  visibility: hidden;
}

.passport-image-icon:hover {
  cursor: pointer;
}

.product-detail-image.zoom:hover {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  max-width: 454px !important;
  overflow: hidden;
}

.passport-image.zoom:hover img {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
}

.passport-container {
  position: sticky;
  top: calc(45px + 1rem);
}

.object-fit-contain {
  object-fit: contain;
}
