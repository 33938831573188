//
// Buttons Base
//

// Button
.btn {
  // Reset outline
  outline: none !important;

  // Reset focus shadow
  &:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none !important;
  }

  // Remove border
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(
      .btn-icon
    ) {
    border: 0;
    padding: calc(#{$btn-padding-y} + #{$btn-border-width})
      calc(#{$btn-padding-x} + #{$btn-border-width});

    &.btn-lg {
      padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width})
        calc(#{$btn-padding-x-lg} + #{$btn-border-width});
    }

    &.btn-sm {
      padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width})
        calc(#{$btn-padding-x-sm} + #{$btn-border-width});
    }
  }

  // Link
  &.btn-link {
    border: 0;
    border-radius: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-decoration: none;
    font-weight: $btn-font-weight;
  }

  // Outline dashed
  &.btn-outline-dashed {
    border: 1px dashed $border-dashed-color;
  }

  // Outline default
  &.btn-outline-default {
    border: 1px solid $input-border-color;
  }

  // Flush
  &.btn-flush {
    @include button-reset();
  }

  // Flex
  &.btn-flex {
    display: inline-flex;
    align-items: center;
  }

  // Align start
  &.btn-trim-start {
    justify-content: flex-start !important;
    padding-left: 0 !important;
  }

  // Align start
  &.btn-trim-end {
    justify-content: flex-end !important;
    padding-right: 0 !important;
  }
}

// Icons
.btn {
  // Font icon
  i {
    display: inline-flex;
    font-size: $font-size-base;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 0;
  }

  // Svg icon
  .svg-icon {
    flex-shrink: 0;
    line-height: 0;
    margin-right: 0.5rem;
  }

  // Icon only button
  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: $input-height;
    width: $input-height;

    // Remove border
    &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
      border: 0;
    }

    // Sizes
    &.btn-sm {
      height: $input-height-sm;
      width: $input-height-sm;
    }

    &.btn-lg {
      height: $input-height-lg;
      width: $input-height-lg;
    }

    &.btn-circle {
      border-radius: 50%;
    }

    i,
    .svg-icon {
      padding: 0;
      margin: 0;
      line-height: 1;
    }
  }
}

// Hover effects
.btn.btn-hover-rise {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10%);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-scale {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-end {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(4deg);
    transition: transform 0.3s ease;
  }
}

.btn.btn-hover-rotate-start {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(-4deg);
    transition: transform 0.3s ease;
  }
}

// remove arrow spinners from number input field
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//custom button : it's look like tab. Implimented on order list page
.oa-tab-btn {
  border: none;
  background: transparent;
  color: #b5b5c2;
  border-bottom: 2px solid transparent;
  &.active,
  &:hover {
    border-bottom: 2px solid #3a86ff;
    color: #3a86ff;
  }
  &:disabled {
    cursor: not-allowed;
  }
}
