.oa-symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    height: 29px;
    width: 29px;
    border-radius: 50%;
  
    .oa-symbol-label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      font-weight: 500;
      color: #FFFFFF;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 50%;
    }
  
    .oa-symbol-badge {
      position: absolute;
      border: 2px solid $body-bg;
      border-radius: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) !important;
    }
  
    &.oa-symbol-square {
      &,
      > img,
      .oa-symbol-label {
        border-radius: 0 !important;
      }
    }
  
    &.oa-symbol-circle {
      &,
      > img,
      .oa-symbol-label {
        border-radius: 50%;
      }
    }
  }
  
  .oa-symbol-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 10px;
  
    .oa-symbol {
      position: relative;
      z-index: 0;
      margin-left: -10px;
      border: 2px solid #FFFFFF;
      transition: all 0.3s ease;
  
      &:hover {
        transition: all 0.3s ease;
        z-index: 1;
      }
    }
  
    &.oa-symbol-hover {
      .oa-symbol {
        cursor: pointer;
      }
    }
  }
  