#invoice_pdf {
  width: 920px;

  table.invoice-pdf--table {
    width: 100%;

    thead {
      tr {
        th {
          color: #616876 !important;
          border: 1px solid #eeeff3;
          padding: 0.5rem;
          vertical-align: bottom;

          &.hsn {
            width: 84px;
            // text-align: center;
          }
          &.qty {
            width: 84px;
            // text-align: center;
          }
          &.igst {
          }
          &.amount {
            width: 84px;
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px solid #eeeff3;
          padding: 0.5rem;

          &.amount {
            text-align: right;
          }

          &.bank-details {
            border: unset !important;
            padding: unset !important;
          }

          &.total-amount {
            height: 50px;
            padding: 1rem;
          }
          &.signature {
            height: 92px;
            text-align: center;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}

@mixin set-width($width) {
  .width-#{$width} {
    width: #{$width}px;
  }
}
